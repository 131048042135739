import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { TDocumentDownloadPanelStrapiComponent } from '../../../types/DocumentDownloadPanelStrapiComponent';
import { TImagePanelStrapiComponent } from '../../../types/ImagePanelStrapiComponent';
import { TProblemPanelStrapiComponent } from '../../../types/ProblemPanelStrapiComponent';
import { StrapiChapterContentDynamicZoneItem } from '../../../utils/strapi';

interface ITableOfContentsChapterPanelProps {
  bookChapter: TBeyondCTCIBookChapter;
  bookPart: TBeyondCTCIBookPart;
}

const TableOfContentsChapterPanel = ({ bookChapter, bookPart }: ITableOfContentsChapterPanelProps) => (
  <ul className="pl-5">
    {bookChapter.attributes.chapterContent
      .filter(
        (chapterContentDynamicZoneItem) =>
          chapterContentDynamicZoneItem.__component !== StrapiChapterContentDynamicZoneItem.bookPanelsBookInfo &&
          chapterContentDynamicZoneItem.title &&
          chapterContentDynamicZoneItem.slug
      )
      .map((chapterContentDynamicZoneItem, index: number) => (
        <li className="pt-1" key={index}>
          <a
            className="text-sm"
            href={`${window?.CONFIG.beyondCTCIBaseUrl}/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}#${
              (
                chapterContentDynamicZoneItem as
                  | TDocumentDownloadPanelStrapiComponent
                  | TProblemPanelStrapiComponent
                  | TImagePanelStrapiComponent
              ).slug
            }`}
          >
            {
              (
                chapterContentDynamicZoneItem as
                  | TDocumentDownloadPanelStrapiComponent
                  | TProblemPanelStrapiComponent
                  | TImagePanelStrapiComponent
              ).title
            }
          </a>
        </li>
      ))}
  </ul>
);

export default TableOfContentsChapterPanel;
