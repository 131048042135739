import { ProblemPanel } from '@interviewingio/iio-react-component-lib';
import { TProblemPanelStrapiComponent } from '../../../types/ProblemPanelStrapiComponent';
import axios from '../../../utils/axios';

const Problem = ({ dynamicZoneItem }: { dynamicZoneItem: TProblemPanelStrapiComponent }) => {
  const fetchArtifact = async (problemId: string, artifactType: string) => {
    try {
      const response = await axios.get(`/api/beyond-ctci/problem/${problemId}/${artifactType}`);
      return response.data?.artifact;
    } catch (error) {
      window.Rollbar.error(`Error fetching ${artifactType}:`, error);
      throw error;
    }
  };

  const fetchProblem = async (problemId: string) => fetchArtifact(problemId, 'markdownProblemStatement');
  const fetchSolution = async (problemId: string) => fetchArtifact(problemId, 'solution');

  return (
    <ProblemPanel
      title={dynamicZoneItem.title}
      slug={dynamicZoneItem.slug}
      fetchProblem={fetchProblem}
      fetchSolution={fetchSolution}
    />
  );
};
export default Problem;
