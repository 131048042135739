import { BookInfoPanel } from '@interviewingio/iio-react-component-lib';
import bookCover from '../../../assets/images/BCTCI/book-cover.webp';

const BookInfo = () => (
  <BookInfoPanel
    description={`For over a decade, Cracking the Coding Interview has been hailed as
  the "bible" of interview prep. Now, Beyond Cracking the Coding
  Interview builds on that foundation to prepare you for today's
  tougher technical interviews and hiring climate.`}
    handleButtonClick={() => {}}
    imageSrc={bookCover}
    subtitle="Beyond Cracking the Coding Interview"
  />
);

export default BookInfo;
