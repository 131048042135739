import { ImagePanel } from '@interviewingio/iio-react-component-lib';
import { TImagePanelStrapiComponent } from '../../../types/ImagePanelStrapiComponent';
import { resolveMediaUrl } from '../../../utils/strapi';

const Image = ({ dynamicZoneItem }: { dynamicZoneItem: TImagePanelStrapiComponent }) => (
  <ImagePanel
    title={dynamicZoneItem.title}
    slug={dynamicZoneItem.slug}
    caption={dynamicZoneItem.caption}
    imageUrl={resolveMediaUrl(dynamicZoneItem.imageMedia.data.attributes.url)}
    imageHeight={`${dynamicZoneItem.imageMedia.data.attributes.height}`}
    imageWidth={`${dynamicZoneItem.imageMedia.data.attributes.width}`}
    imageAltText={dynamicZoneItem.imageMedia.data.attributes.alternativeText}
  />
);

export default Image;
