import axios from '../../../utils/axios';
import { useForm, useFieldArray, UseFormRegister, Control } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

import BooleanTextToggle from '../../../elements/BooleanTextToggle';

type TopicPreference = { name: string; topicSlug: string; easy: boolean; medium: boolean; hard: boolean };
type FormTier = {
  topics: TopicPreference[];
  tierSlug: string;
  title: string;
};

type BookPreferences = {
  tiers: FormTier[];
  source: 'book' | 'non-book' | 'both';
};

type ManifestTopic = {
  tier: string;
  label: string;
  slug: string;
};
type Manifest = { topics: Record<string, ManifestTopic>; tiers: Record<string, { label: string }> };
type TierFormParams = {
  tierIndex: number;
  control: Control<BookPreferences>;
  register: UseFormRegister<BookPreferences>;
};

type Difficulty = 'easy' | 'medium' | 'hard';

const DifficultyToggle = ({
  name,
  control,
  difficulty,
}: {
  control: Control<BookPreferences, any>;
  name: any;
  difficulty: Difficulty;
}) => {
  const difficultyStyling = {
    easy: { label: 'Easy', bgColor: 'bg-green-300', textColor: 'text-green-700' },
    medium: { label: 'Medium', textColor: 'text-amber-700', bgColor: 'bg-amber-300' },
    hard: { label: 'Hard', bgColor: 'bg-red-200', textColor: 'text-red-600' },
  };
  const { label, bgColor, textColor } = difficultyStyling[difficulty];
  return <BooleanTextToggle label={label} control={control} name={name} bgColor={bgColor} textColor={textColor} />;
};

const TierForm = ({ tierIndex, control }: TierFormParams) => {
  const { fields: topics } = useFieldArray({
    control,
    name: `tiers.${tierIndex}.topics`,
  });

  return (
    <div>
      {topics &&
        topics.map((topic, topicIndex) => (
          <div key={topic.id} className="flex max-w-2xl justify-between">
            <div className="pt-2 text-lg ">{(topic as unknown as TopicPreference).name}</div>
            <div className="flex w-1/3 items-center justify-around">
              <DifficultyToggle
                name={`tiers.${tierIndex}.topics.${topicIndex}.easy`}
                control={control}
                difficulty="easy"
              />
              <DifficultyToggle
                name={`tiers.${tierIndex}.topics.${topicIndex}.medium`}
                control={control}
                difficulty="medium"
              />
              <DifficultyToggle
                name={`tiers.${tierIndex}.topics.${topicIndex}.hard`}
                control={control}
                difficulty="hard"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

const BookPreferencesForm = ({ bookPreferenceDefaults }: { bookPreferenceDefaults: BookPreferences }) => {
  const { register, handleSubmit, control } = useForm({ defaultValues: bookPreferenceDefaults });
  const { fields: tiers } = useFieldArray({ control, name: 'tiers' });

  const onSubmit = (data: BookPreferences) => {
    axios.post('api/beyond-ctci/ai-interview-from-preferences', data).then((response) => {
      const { interviewUrl } = response.data;
      window.location.replace(interviewUrl);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2 mt-8 text-2xl font-semibold"> Problem selection </div>
      <legend className="mb-2 ">We'll randomly select from the following set of problems:</legend>
      <div className="my-8 space-y-2">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="book"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Problems presented in the book
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="non-book"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Problems not presented in the book
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="both"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Both
        </label>
      </div>
      {tiers &&
        tiers.map((tier, tierIndex) => (
          <div key={tier.id} style={{ marginBottom: '20px' }}>
            <div className="text-xl font-semibold"> {tier.title} </div>
            <TierForm tierIndex={tierIndex} control={control} register={register} />
          </div>
        ))}

      {
        <div className="mb-16">
          <button
            type="submit"
            className="text-grey-600 rounded-md bg-amber-300 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-amber-400"
          >
            Start your AI Interview
          </button>
        </div>
      }
    </form>
  );
};
const BeyondCTCIBookAIInterviewLaunchPage = () => {
  const { data: manifest } = useQuery({
    queryKey: ['manifest'],
    queryFn: () => axios.get(`api/beyond-ctci/authored-content-index`).then((response) => response.data as Manifest),
  });

  let bookPreferenceDefaults: BookPreferences = { tiers: null, source: 'book' };

  if (manifest) {
    const tiers = {} as Record<string, FormTier>;
    for (const topic of Object.values(manifest.topics)) {
      if (!tiers[topic.tier]) {
        tiers[topic.tier] = { title: manifest.tiers[topic.tier].label, tierSlug: topic.tier, topics: [] };
      }
      tiers[topic.tier].topics.push({
        name: topic.label,
        topicSlug: topic.slug,
        easy: false,
        medium: false,
        hard: false,
      });
    }
    bookPreferenceDefaults = { ...bookPreferenceDefaults, tiers: Object.values(tiers) };
  }

  return (
    <>
      <div className="text-color-black flex flex-col gap-8 bg-yellow-500 px-64 py-12">
        <div className="text-4xl font-semibold"> Settings </div>
        <div className="text-l">
          Set the types of problems, topics, and difficulty levels you’d like to AI Interviewer to ask you.
        </div>
      </div>
      <div className="px-64">
        {bookPreferenceDefaults.tiers && <BookPreferencesForm bookPreferenceDefaults={bookPreferenceDefaults} />}
      </div>
    </>
  );
};

export default BeyondCTCIBookAIInterviewLaunchPage;
