import { TCodeBlockStrapiComponent } from '../../../types/CodeBlockPanelStrapiComponent';
import { CodeBlockPanel } from '@interviewingio/iio-react-component-lib';

const CodeBlock = ({ dynamicZoneItem }: { dynamicZoneItem: TCodeBlockStrapiComponent }) => (
  <CodeBlockPanel
    title={dynamicZoneItem.title}
    slug={dynamicZoneItem.slug}
    syntaxHighlighting={dynamicZoneItem.syntaxHighlighting}
    sourceCode={dynamicZoneItem.sourceCode}
    caption={dynamicZoneItem.caption}
  />
);

export default CodeBlock;
