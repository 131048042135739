import React, { useEffect } from 'react';
import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { BookTitle } from '@interviewingio/iio-react-component-lib';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import axios from '../../../utils/axios';

const BeyondCTCIBookLandingPage = () => {
  const isUserAllowed = useCanViewBeyondCTCIBook();

  useEffect(() => {
    const fetchFirstChapter = async () => {
      try {
        const response = await axios.get('api/strapi/book/table-of-contents');
        const tableOfContents: TBeyondCTCIBookPart[] = await response.data;

        const partChapters = tableOfContents.flatMap((part) =>
          part.attributes.book_chapters.data.map((chapter) => ({
            partId: part.id,
            partSlug: part.attributes.Slug,
            partName: part.attributes.Name,
            chapterId: chapter.id,
            chapterName: chapter.attributes.Name,
            chapterSlug: chapter.attributes.Slug,
            hasPanels: !!chapter.attributes.chapterContent.length,
          }))
        );
        const partChaptersWithPanels = partChapters.filter((partChapter) => partChapter.hasPanels);

        const firstChapterSlugs = partChaptersWithPanels.length > 0 ? partChaptersWithPanels[0] : null;

        if (firstChapterSlugs) {
          const chapterPath = `/beyond-ctci/${firstChapterSlugs.partSlug}/${firstChapterSlugs.chapterSlug}`;
          window.location.href = chapterPath;
        }
      } catch (error) {
        window.Rollbar.error('Error fetching first chapter:', error);
      }
    };

    fetchFirstChapter();
  }, []);

  if (!isUserAllowed) return null;

  return (
    <div className="mx-auto flex max-w-screen-lg justify-center gap-4">
      <div className="flex-1">
        <BookTitle />
      </div>
    </div>
  );
};

export default BeyondCTCIBookLandingPage;
