import { createContext, FC, ReactElement, useContext, useState } from 'react';

type TBCTCIContext = {
  currentPartId: number;
  setCurrentPartId: (index: number) => void;
  currentChapterId: string;
  setCurrentChapterId: (index: string) => void;
};

const BCTCIContext = createContext<TBCTCIContext>({
  currentPartId: 0,
  setCurrentPartId: () => {},
  currentChapterId: '',
  setCurrentChapterId: () => {},
});

export type ProviderProps = {
  children: ReactElement | ReactElement[];
};

const BCTCIContextProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [currentPartId, setCurrentPartId] = useState<number>(-1);
  const [currentChapterId, setCurrentChapterId] = useState<string>('');

  return (
    <BCTCIContext.Provider
      value={{
        currentPartId,
        setCurrentPartId,
        currentChapterId,
        setCurrentChapterId,
      }}
    >
      {children}
    </BCTCIContext.Provider>
  );
};

function useBCTCIContext() {
  const context = useContext(BCTCIContext);
  if (!context) {
    throw new Error('useBCTCIContext must be used within a BCTCIContextProvider');
  }
  return context;
}

export { BCTCIContextProvider, useBCTCIContext };
