import { ExternalResourcePanel } from '@interviewingio/iio-react-component-lib';
import { TExternalResourcePanelStrapiComponent } from '../../../types/ExternalResourcePanelStrapiComponent';

const ExternalResource = ({ dynamicZoneItem }: { dynamicZoneItem: TExternalResourcePanelStrapiComponent }) => (
  <ExternalResourcePanel
    title={dynamicZoneItem.title}
    buttonLabel={dynamicZoneItem.openButtonLabel}
    slug={dynamicZoneItem.slug}
    heading={dynamicZoneItem.heading}
    subHeading={dynamicZoneItem.subheading}
    externalResourceUrl={dynamicZoneItem.externalResourceUrl}
  />
);

export default ExternalResource;
