import { Disclosure } from '@headlessui/react';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import TableOfContentsChapterTrigger from './TableOfContentsChapterTrigger';
import TableOfContentsChapterPanel from './TableOfContentsChapterPanel';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';

interface ITableOfContentsPartPanelProps {
  bookPart: TBeyondCTCIBookPart;
}

const TableOfContentsPartPanel = ({ bookPart }: ITableOfContentsPartPanelProps) => {
  const { currentChapterId } = useBCTCIContext();

  return (
    <ul>
      {bookPart.attributes.book_chapters.data.map((bookChapter: TBeyondCTCIBookChapter, chapterIndex: number) => (
        <li className="pt-1" key={chapterIndex}>
          <Disclosure defaultOpen={bookChapter.id === currentChapterId}>
            {({ open: openL2 }) => (
              <div className="flex flex-col pl-1">
                <Disclosure.Button className={`flex flex-row`}>
                  <TableOfContentsChapterTrigger bookChapter={bookChapter} bookPart={bookPart} open={openL2} />
                </Disclosure.Button>

                <Disclosure.Panel>
                  <TableOfContentsChapterPanel bookChapter={bookChapter} bookPart={bookPart} />
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </li>
      ))}
    </ul>
  );
};

export default TableOfContentsPartPanel;
