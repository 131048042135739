// WAI-ARIA: https://www.w3.org/TR/wai-aria-practices-1.2/#disclosure
import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,

  // Types
  ContextType,
  Dispatch,
  ElementType,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  Ref,
} from 'react';

import { Props } from './Dependencies/types';
import { match } from './Dependencies/utils';
import { forwardRefWithAs, render, Features, PropsForFeatures } from './Dependencies/utils';
import { optionalRef, useSyncRefs } from './Dependencies/hooks';
import { useId } from './Dependencies/hooks';
import { Keys } from './Dependencies/keyboard';
import { isDisabledReactIssue7711 } from './Dependencies/utils';
import { OpenClosedProvider, State, useOpenClosed } from './Dependencies/openClosed';
import { useResolveButtonType } from './Dependencies/hooks';
import { getOwnerDocument } from './Dependencies/utils';
import { useEvent } from './Dependencies/hooks';

enum DisclosureStates {
  Open,
  Closed,
}

interface StateDefinition {
  disclosureState: DisclosureStates;

  linkedPanel: boolean;

  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  panelRef: MutableRefObject<HTMLDivElement | null>;

  buttonId: string;
  panelId: string;
}

enum ActionTypes {
  ToggleDisclosure,
  CloseDisclosure,
  ForceOpenDisclosure,

  SetButtonId,
  SetPanelId,

  LinkPanel,
  UnlinkPanel,
}

type Actions =
  | { type: ActionTypes.ToggleDisclosure }
  | { type: ActionTypes.CloseDisclosure }
  | { type: ActionTypes.ForceOpenDisclosure }
  | { type: ActionTypes.SetButtonId; buttonId: string }
  | { type: ActionTypes.SetPanelId; panelId: string }
  | { type: ActionTypes.LinkPanel }
  | { type: ActionTypes.UnlinkPanel };

const reducers: {
  [P in ActionTypes]: (state: StateDefinition, action: Extract<Actions, { type: P }>) => StateDefinition;
} = {
  [ActionTypes.ToggleDisclosure]: (state) => ({
    ...state,
    disclosureState: match(state.disclosureState, {
      [DisclosureStates.Open]: DisclosureStates.Closed,
      [DisclosureStates.Closed]: DisclosureStates.Open,
    }),
  }),
  [ActionTypes.CloseDisclosure]: (state) => {
    if (state.disclosureState === DisclosureStates.Closed) return state;
    return { ...state, disclosureState: DisclosureStates.Closed };
  },
  [ActionTypes.ForceOpenDisclosure]: (state) => {
    if (state.disclosureState === DisclosureStates.Open) return state;
    return { ...state, disclosureState: DisclosureStates.Open };
  },
  [ActionTypes.LinkPanel](state) {
    if (state.linkedPanel === true) return state;
    return { ...state, linkedPanel: true };
  },
  [ActionTypes.UnlinkPanel](state) {
    if (state.linkedPanel === false) return state;
    return { ...state, linkedPanel: false };
  },
  [ActionTypes.SetButtonId](state, action) {
    if (state.buttonId === action.buttonId) return state;
    return { ...state, buttonId: action.buttonId };
  },
  [ActionTypes.SetPanelId](state, action) {
    if (state.panelId === action.panelId) return state;
    return { ...state, panelId: action.panelId };
  },
};

const DisclosureContext = createContext<[StateDefinition, Dispatch<Actions>] | null>(null);
DisclosureContext.displayName = 'DisclosureContext';

function useDisclosureContext(component: string) {
  const context = useContext(DisclosureContext);
  if (context === null) {
    const err = new Error(`<${component} /> is missing a parent <Disclosure /> component.`);
    if (Error.captureStackTrace) Error.captureStackTrace(err, useDisclosureContext);
    throw err;
  }
  return context;
}

const DisclosureAPIContext = createContext<{
  close(focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>): void;
  forceOpen(focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>): void;
} | null>(null);
DisclosureAPIContext.displayName = 'DisclosureAPIContext';

function useDisclosureAPIContext(component: string) {
  const context = useContext(DisclosureAPIContext);
  if (context === null) {
    const err = new Error(`<${component} /> is missing a parent <Disclosure /> component.`);
    if (Error.captureStackTrace) Error.captureStackTrace(err, useDisclosureAPIContext);
    throw err;
  }
  return context;
}

const DisclosurePanelContext = createContext<string | null>(null);
DisclosurePanelContext.displayName = 'DisclosurePanelContext';

function useDisclosurePanelContext() {
  return useContext(DisclosurePanelContext);
}

function stateReducer(state: StateDefinition, action: Actions) {
  return match(action.type, reducers, state, action);
}

// ---

const DEFAULT_DISCLOSURE_TAG = Fragment;
interface DisclosureRenderPropArg {
  open: boolean;
  close(focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>): void;
  forceOpen(focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>): void;
}

const DisclosureRoot = forwardRefWithAs(function Disclosure<TTag extends ElementType = typeof DEFAULT_DISCLOSURE_TAG>(
  props: Props<TTag, DisclosureRenderPropArg> & {
    defaultOpen?: boolean;
  },
  ref: Ref<TTag>
) {
  const { defaultOpen = false, ...theirProps } = props;
  const buttonId = `headlessui-disclosure-button-${useId()}`;
  const panelId = `headlessui-disclosure-panel-${useId()}`;
  const internalDisclosureRef = useRef<HTMLElement | null>(null);
  const disclosureRef = useSyncRefs(
    ref,
    optionalRef(
      (ref) => {
        internalDisclosureRef.current = ref as unknown as HTMLElement | null;
      },
      props.as === undefined ||
        // @ts-expect-error The `as` prop _can_ be a Fragment
        props.as === Fragment
    )
  );

  const panelRef = useRef<StateDefinition['panelRef']['current']>(null);
  const buttonRef = useRef<StateDefinition['buttonRef']['current']>(null);

  const reducerBag = useReducer(stateReducer, {
    disclosureState: defaultOpen ? DisclosureStates.Open : DisclosureStates.Closed,
    linkedPanel: false,
    buttonRef,
    panelRef,
    buttonId,
    panelId,
  } as StateDefinition);
  const [{ disclosureState }, dispatch] = reducerBag;

  useEffect(() => dispatch({ type: ActionTypes.SetButtonId, buttonId }), [buttonId, dispatch]);
  useEffect(() => dispatch({ type: ActionTypes.SetPanelId, panelId }), [panelId, dispatch]);
  useEffect(() => {
    if (defaultOpen === true) dispatch({ type: ActionTypes.ForceOpenDisclosure });
  }, [defaultOpen, dispatch]);

  const close = useEvent((focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => {
    dispatch({ type: ActionTypes.CloseDisclosure });
    const ownerDocument = getOwnerDocument(internalDisclosureRef);
    if (!ownerDocument) return;

    const restoreElement = (() => {
      if (!focusableElement) return ownerDocument.getElementById(buttonId);
      if (focusableElement instanceof HTMLElement) return focusableElement;
      if (focusableElement.current instanceof HTMLElement) return focusableElement.current;

      return ownerDocument.getElementById(buttonId);
    })();

    restoreElement?.focus();
  });

  const forceOpen = useEvent((focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => {
    dispatch({ type: ActionTypes.ForceOpenDisclosure });
    const ownerDocument = getOwnerDocument(internalDisclosureRef);
    if (!ownerDocument) return;

    const restoreElement = (() => {
      if (!focusableElement) return ownerDocument.getElementById(buttonId);
      if (focusableElement instanceof HTMLElement) return focusableElement;
      if (focusableElement.current instanceof HTMLElement) return focusableElement.current;

      return ownerDocument.getElementById(buttonId);
    })();

    restoreElement?.focus();
  });

  const api = useMemo<ContextType<typeof DisclosureAPIContext>>(() => ({ close, forceOpen }), [close, forceOpen]);

  const slot = useMemo<DisclosureRenderPropArg>(
    () => ({ open: disclosureState === DisclosureStates.Open, close, forceOpen }),
    [disclosureState, close, forceOpen]
  );

  const ourProps = {
    ref: disclosureRef,
  };

  return (
    <DisclosureContext.Provider value={reducerBag}>
      <DisclosureAPIContext.Provider value={api}>
        <OpenClosedProvider
          value={match(disclosureState, {
            [DisclosureStates.Open]: State.Open,
            [DisclosureStates.Closed]: State.Closed,
          })}
        >
          {render({
            ourProps,
            theirProps,
            slot,
            defaultTag: DEFAULT_DISCLOSURE_TAG,
            name: 'Disclosure',
          })}
        </OpenClosedProvider>
      </DisclosureAPIContext.Provider>
    </DisclosureContext.Provider>
  );
});

// ---

const DEFAULT_BUTTON_TAG = 'button' as const;
interface ButtonRenderPropArg {
  open: boolean;
}
type ButtonPropsWeControl = 'id' | 'type' | 'aria-expanded' | 'aria-controls' | 'onKeyDown' | 'onClick';

const Button = forwardRefWithAs(function Button<TTag extends ElementType = typeof DEFAULT_BUTTON_TAG>(
  props: Props<TTag, ButtonRenderPropArg, ButtonPropsWeControl>,
  ref: Ref<HTMLButtonElement>
) {
  const [state, dispatch] = useDisclosureContext('Disclosure.Button');
  const panelContext = useDisclosurePanelContext();
  const isWithinPanel = panelContext === null ? false : panelContext === state.panelId;

  const internalButtonRef = useRef<HTMLButtonElement | null>(null);
  const buttonRef = useSyncRefs(internalButtonRef, ref, !isWithinPanel ? state.buttonRef : null);

  const handleKeyDown = useEvent((event: ReactKeyboardEvent<HTMLButtonElement>) => {
    if (isWithinPanel) {
      if (state.disclosureState === DisclosureStates.Closed) return;

      switch (event.key) {
        case Keys.Space:
        case Keys.Enter:
          event.preventDefault();
          event.stopPropagation();
          dispatch({ type: ActionTypes.ToggleDisclosure });
          state.buttonRef.current?.focus();
          break;
      }
    } else {
      switch (event.key) {
        case Keys.Space:
        case Keys.Enter:
          event.preventDefault();
          event.stopPropagation();
          dispatch({ type: ActionTypes.ToggleDisclosure });
          break;
      }
    }
  });

  const handleKeyUp = useEvent((event: ReactKeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case Keys.Space:
        // Required for firefox, event.preventDefault() in handleKeyDown for
        // the Space key doesn't cancel the handleKeyUp, which in turn
        // triggers a *click*.
        event.preventDefault();
        break;
    }
  });

  const handleClick = useEvent((event: ReactMouseEvent) => {
    if (isDisabledReactIssue7711(event.currentTarget)) return;
    if (props.disabled) return;

    if (isWithinPanel) {
      dispatch({ type: ActionTypes.ToggleDisclosure });
      state.buttonRef.current?.focus();
    } else {
      dispatch({ type: ActionTypes.ToggleDisclosure });
    }
  });

  const slot = useMemo<ButtonRenderPropArg>(() => ({ open: state.disclosureState === DisclosureStates.Open }), [state]);

  const type = useResolveButtonType(props, internalButtonRef);
  const theirProps = props;
  const ourProps = isWithinPanel
    ? { ref: buttonRef, type, onKeyDown: handleKeyDown, onClick: handleClick }
    : {
        ref: buttonRef,
        id: state.buttonId,
        type,
        'aria-expanded': props.disabled ? undefined : state.disclosureState === DisclosureStates.Open,
        'aria-controls': state.linkedPanel ? state.panelId : undefined,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp,
        onClick: handleClick,
      };

  return render({
    ourProps,
    theirProps,
    slot,
    defaultTag: DEFAULT_BUTTON_TAG,
    name: 'Disclosure.Button',
  });
});

// ---

const DEFAULT_PANEL_TAG = 'div' as const;
interface PanelRenderPropArg {
  open: boolean;
  close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;
}
type PanelPropsWeControl = 'id';

const PanelRenderFeatures = Features.RenderStrategy | Features.Static;

const Panel = forwardRefWithAs(function Panel<TTag extends ElementType = typeof DEFAULT_PANEL_TAG>(
  props: Props<TTag, PanelRenderPropArg, PanelPropsWeControl> & PropsForFeatures<typeof PanelRenderFeatures>,
  ref: Ref<HTMLDivElement>
) {
  const [state, dispatch] = useDisclosureContext('Disclosure.Panel');
  const { close } = useDisclosureAPIContext('Disclosure.Panel');

  const panelRef = useSyncRefs(ref, state.panelRef, (el) => {
    dispatch({ type: el ? ActionTypes.LinkPanel : ActionTypes.UnlinkPanel });
  });

  const usesOpenClosedState = useOpenClosed();
  const visible = (() => {
    if (usesOpenClosedState !== null) {
      return usesOpenClosedState === State.Open;
    }

    return state.disclosureState === DisclosureStates.Open;
  })();

  const slot = useMemo<PanelRenderPropArg>(
    () => ({ open: state.disclosureState === DisclosureStates.Open, close }),
    [state, close]
  );

  const theirProps = props;
  const ourProps = {
    ref: panelRef,
    id: state.panelId,
  };

  return (
    <DisclosurePanelContext.Provider value={state.panelId}>
      {render({
        ourProps,
        theirProps,
        slot,
        defaultTag: DEFAULT_PANEL_TAG,
        features: PanelRenderFeatures,
        visible,
        name: 'Disclosure.Panel',
      })}
    </DisclosurePanelContext.Provider>
  );
});

// ---

export const Disclosure = Object.assign(DisclosureRoot, { Button, Panel });
