import { useEffect } from 'react';

const useScrollToHash = () => {
  useEffect(() => {
    const handleHashScroll = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          // Get the offset of the fixed header if it's present
          const headerOffset = 100; // Adjust this to your header's height

          // Scroll to the element smoothly first
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });

          // Use a timeout to ensure the scroll adjustment occurs after the scrollIntoView
          setTimeout(() => {
            // Calculate the position with the offset
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerOffset;

            // Scroll to the adjusted position
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }, 300); // Timeout duration should be less than the duration of the smooth scroll
        }
      }
    };

    handleHashScroll();

    // Optional: Add event listener for hash changes if needed
    window.addEventListener('hashchange', handleHashScroll);

    return () => {
      window.removeEventListener('hashchange', handleHashScroll);
    };
  }, []);
};

export default useScrollToHash;
