import { ProblemSetPanel } from '@interviewingio/iio-react-component-lib';
import axios from '../../../utils/axios';
import { TProblemSetPanelStrapiComponent } from '../../../types/ProblemSetPanelStrapiComponent';

const Problem = ({ dynamicZoneItem }: { dynamicZoneItem: TProblemSetPanelStrapiComponent }) => {
  const fetchArtifact = async (problemId: string, artifactType: string) => {
    try {
      const response = await axios.get(`/api/beyond-ctci/problem/${problemId}/${artifactType}`);
      return response.data?.artifact;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching ${artifactType}:`, error);
      throw error;
    }
  };

  const fetchProblem = async (problemId: string) => fetchArtifact(problemId, 'markdownProblemStatement');
  const fetchSolution = async (problemId: string) => fetchArtifact(problemId, 'solution');

  return (
    <ProblemSetPanel
      title={dynamicZoneItem.title}
      slug={dynamicZoneItem.slug}
      problems={dynamicZoneItem.problems}
      fetchProblem={fetchProblem}
      fetchSolution={fetchSolution}
    />
  );
};

export default Problem;
