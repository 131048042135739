import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';

const AiInterviewerCTA = () => (
  <div className="bg-iioYellow w-full p-2">
    <H2 color="text-black" fontFamily={FONT_FAMILY.HEADING}>
      AI Interviewer Shuffle Mode
    </H2>
    <p>Practice new and existing problems from the topics you've covered so far.</p>
    <a
      href="/beyond-ctci/interview-ai"
      target="_blank"
      className="text-md my-2 block w-full bg-black p-3 text-center font-semibold text-white no-underline"
    >
      Practice Now
    </a>
  </div>
);

export default AiInterviewerCTA;
