import React from 'react';

import { useCanViewBeyondCTCIBook } from '../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookTableOfContents } from '../../hooks/BeyondCTCIBook/useBeyondCTCIBookTableOfContents';
import { TBeyondCTCIBookTableOfContents } from '../../types/BeyondCTCIBookTableOfContents';
import '../../pages/BeyondCTCIBook/BeyondCTCIBookStyles.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import AiInterviewerCTA from './AiInterviewerCTA';
import ContactPanel from './ContactPanel';
import TableOfContentsAccordion from './Accordion/TableOfContentsAccordion';

const BeyondCTCIBookTableOfContentsContent = ({
  tableOfContents,
  drawerOpen,
  setDrawerOpen,
}: {
  tableOfContents: TBeyondCTCIBookTableOfContents;
  drawerOpen: boolean;
  setDrawerOpen?: (value: boolean) => void;
}) => {
  const handleDrawerClick = () => {
    setDrawerOpen?.(!drawerOpen);
  };

  return (
    <div>
      <div className="xl:fixed">
        {drawerOpen && (
          <ChevronLeftIcon
            onClick={handleDrawerClick}
            className="absolute -right-8 top-4 z-10 hidden h-8 min-w-fit cursor-pointer rounded bg-white px-2 py-1 shadow-lg xl:block"
          />
        )}
        {!drawerOpen && (
          <ChevronRightIcon
            onClick={handleDrawerClick}
            className="my-4 ml-3 hidden h-8 min-w-fit cursor-pointer rounded bg-white px-2 py-1 shadow-lg xl:block"
          />
        )}

        <div
          className={`bg-bctci-gray-sidebar-default hide-scrollbar inline-flex h-screen w-full flex-col overflow-y-scroll xl:max-w-[324px] ${
            !drawerOpen && 'hidden'
          }`}
        >
          <aside className="w-full px-4 py-3">
            <TableOfContentsAccordion tableOfContents={tableOfContents} />
          </aside>
          <ContactPanel />
          <div className="my-2 w-full" />
          <AiInterviewerCTA />
          {/* Add whitespace under AI Interviewer CTA */}
          <div className="my-5 w-full py-5" />
        </div>
      </div>
    </div>
  );
};

export interface BeyondCTCIBookTableOfContentsSidebarProps {
  drawerOpen: boolean;
  setDrawerOpen?: (value: boolean) => void;
}

const BeyondCTCIBookTableOfContentsSidebar = ({
  drawerOpen,
  setDrawerOpen,
}: BeyondCTCIBookTableOfContentsSidebarProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookTableOfContents, isLoading, isFetched } = useBeyondCTCIBookTableOfContents();

  if (!isUserAllowed) return null;

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {isFetched && (
        <BeyondCTCIBookTableOfContentsContent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          tableOfContents={bookTableOfContents}
        />
      )}
    </>
  );
};

export default BeyondCTCIBookTableOfContentsSidebar;
