import { PropsWithChildren, useEffect, useState } from 'react';

import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookChapter as useBeyondCTCIBookChapter } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookChapter';
import { ChapterContentDynamicZoneItem, TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { StrapiChapterContentDynamicZoneItem } from '../../../utils/strapi';
import DocumentDownload from '../ChapterPanels/DocumentDownload';
import Problem from '../ChapterPanels/Problem';
import InterviewReplayLauncher from '../ChapterPanels/InterviewReplayLauncher';
import Image from '../ChapterPanels/Image';
import BookInfoPanelContainer from '../ChapterPanels/BookInfo';
import RichText from '../ChapterPanels/RichText';
import CodeBlock from '../ChapterPanels/CodeBlock';
import { useBeyondCTCIBookTableOfContents } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookTableOfContents';
import BeyondCTCIBookTableOfContentsSidebar from '../../../components/BeyondCTCIBookTableOfContents/BeyondCTCIBookTableOfContentsSidebar';
import { BCTCIContextProvider, useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import TableOfContentsMobileContainer from '../../../components/BeyondCTCIBookTableOfContents/TableOfContentsMobileContainer';
import ProblemSet from '../ChapterPanels/ProblemSet';
import useScrollToHash from '../../../hooks/BeyondCTCIBook/useScrollToHash';
import ExternalResource from '../ChapterPanels/ExternalResource';

type BookChapterPageProps = {
  chapterSlug: string;
};

type TChapterLink = {
  name: string;
  url: string;
};

const strapiChapterContentDynamicZoneItemContainerMap: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in ChapterContentDynamicZoneItem['__component']]: React.FC<any>;
} = {
  [StrapiChapterContentDynamicZoneItem.bookPanelsRichText]: RichText,
  [StrapiChapterContentDynamicZoneItem.bookPanelsDocumentDownload]: DocumentDownload,
  [StrapiChapterContentDynamicZoneItem.bookPanelsBookInfo]: BookInfoPanelContainer,
  [StrapiChapterContentDynamicZoneItem.bookPanelsImage]: Image,
  [StrapiChapterContentDynamicZoneItem.bookPanelsProblem]: Problem,
  [StrapiChapterContentDynamicZoneItem.bookPanelsInterviewReplayLauncher]: InterviewReplayLauncher,
  [StrapiChapterContentDynamicZoneItem.bookPanelsProblemSet]: ProblemSet,
  [StrapiChapterContentDynamicZoneItem.bookPanelsCodeBlock]: CodeBlock,
  [StrapiChapterContentDynamicZoneItem.bookPanelsExternalResource]: ExternalResource,
};

const ChapterPanels = ({ bookChapter }: PropsWithChildren<{ bookChapter: TBeyondCTCIBookChapter }>) => {
  const renderedComponents: JSX.Element[] = bookChapter.attributes.chapterContent.map(
    (chapterContentDynamicZoneItem, i) => {
      const Component = strapiChapterContentDynamicZoneItemContainerMap[chapterContentDynamicZoneItem.__component];

      if (!Component) return null;

      return <Component key={i} dynamicZoneItem={chapterContentDynamicZoneItem} />;
    }
  );
  return <>{renderedComponents.filter(Boolean)}</>;
};

const ChapterContent = ({ bookChapter }: PropsWithChildren<{ bookChapter: TBeyondCTCIBookChapter }>) => {
  const { data: tableOfContents, isFetched: isTOCFetched } = useBeyondCTCIBookTableOfContents();

  const [nextChapterLink, setNextChapterLink] = useState<TChapterLink>(null);
  const { setCurrentChapterId, setCurrentPartId } = useBCTCIContext();

  useEffect(() => {
    if (tableOfContents) {
      // Extract partSlug and chapterSlug from the window location
      const pathSegments = window.location.pathname.split('/').filter(Boolean);
      const partSlug = pathSegments[pathSegments.length - 2];
      const chapterSlug = pathSegments[pathSegments.length - 1];

      const partChapters = tableOfContents.flatMap((part) =>
        part.attributes.book_chapters.data.map((chapter) => ({
          partId: part.id,
          partSlug: part.attributes.Slug,
          partName: part.attributes.Name,
          chapterId: chapter.id,
          chapterName: chapter.attributes.Name,
          chapterSlug: chapter.attributes.Slug,
          hasPanels: !!chapter.attributes.chapterContent.length,
        }))
      );
      const partChaptersWithPanels = partChapters.filter((partChapter) => partChapter.hasPanels);

      const currentChapter = partChapters.find(
        (chapterAndPart) => chapterAndPart.chapterSlug === chapterSlug && chapterAndPart.partSlug === partSlug
      );
      if (currentChapter) {
        const partIndex = partChapters.findIndex((partChapter) => partChapter.partSlug === partSlug);
        const chapterIndex = partChapters.findIndex((partChapter) => partChapter.chapterSlug === chapterSlug);

        setCurrentPartId(partChapters[partIndex].partId);
        setCurrentChapterId(partChapters[chapterIndex].chapterId);

        // Determine next chapters
        const partChapterIndex = partChaptersWithPanels.findIndex(
          (partChapter) => partChapter.chapterSlug === chapterSlug
        );
        if (partChapterIndex < partChaptersWithPanels.length - 1) {
          const nextChapter = partChaptersWithPanels[partChapterIndex + 1];
          setNextChapterLink({
            name: nextChapter.chapterName,
            url: `/beyond-ctci/${nextChapter.partSlug}/${nextChapter.chapterSlug}`,
          });
        }
      }
    }
  }, [tableOfContents]);

  useScrollToHash();

  return (
    <div className="mx-auto mb-12 sm:mt-6 md:mb-10 md:mt-12 xl:pl-16">
      <h1 className="font-heading p-2 text-4xl md:text-5xl">{bookChapter.attributes.Name}</h1>
      <ChapterPanels bookChapter={bookChapter} />
      <div className="p-2 text-center sm:text-right">
        <div className="w-auto text-lg text-gray-600">{nextChapterLink ? 'Next chapter' : 'The end'}</div>
        <div className="font-heading mt-2 flex w-auto justify-center text-2xl sm:justify-end">
          {isTOCFetched && nextChapterLink && (
            <a className="flex flex-col items-center space-x-2 sm:flex-row" href={nextChapterLink.url}>
              <span>{nextChapterLink.name}</span>
              <span className="bg-iioYellow mt-2 flex h-8 w-8 rotate-90 items-center justify-center rounded sm:mt-0 sm:rotate-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-800"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const ChapterPage = ({ chapterSlug }: BookChapterPageProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookChapter, isLoading, isFetched } = useBeyondCTCIBookChapter(chapterSlug);
  const [drawerOpen, setDrawerOpen] = useState(true);

  // TODO: Setup appropriate redirects
  if (!isUserAllowed) return null;
  if (isFetched && !bookChapter) return null;

  return (
    <BCTCIContextProvider>
      <div className={`overflow-x-scroll xl:grid ${drawerOpen ? 'grid-cols-chapter-page-sidebar-open' : ''}`}>
        <div className="xl:hidden">
          <TableOfContentsMobileContainer />
        </div>

        <div className="hidden xl:block">
          <BeyondCTCIBookTableOfContentsSidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </div>
        <div className={`w-full ${drawerOpen ? 'mx-auto max-w-[924px]' : ''}`}>
          {isLoading && <p>Loading...</p>}
          {isFetched && bookChapter && <ChapterContent bookChapter={bookChapter} />}
        </div>
      </div>
    </BCTCIContextProvider>
  );
};

export default ChapterPage;
