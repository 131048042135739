import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';

const ContactPanel = () => (
  <div className="bg-bctci-gray-sidebar-dark mx-4 px-4 py-2">
    <H2 color="text-black" fontFamily={FONT_FAMILY.HEADING}>
      Find a mistake?
    </H2>
    <p>
      If something doesnt look right, please email <a href="mailto:support@interviewing.io">support@interviewing.io</a>
    </p>
  </div>
);

export default ContactPanel;
