import { Control, Controller } from 'react-hook-form';

const BooleanTextToggle = ({
  name,
  control,
  label,
  bgColor,
  textColor,
}: {
  label: string;
  control: Control<any, any>;
  name: any;
  bgColor: string;
  textColor: string;
}) => {
  const renderToggle = ({ field: { value, onChange } }: any) => {
    const backgroundColor = value ? bgColor : 'bg-gray-300';
    const color = value ? textColor : 'black';

    return (
      <span onClick={() => onChange(!value)} className={`cursor-pointer px-4 py-1 ${backgroundColor} ${color} `}>
        {label}
      </span>
    );
  };

  return <Controller name={name} control={control} render={renderToggle} />;
};

export default BooleanTextToggle;
