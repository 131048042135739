import { TBeyondCTCIBookChapter } from '../../types/BeyondCTCIBookChapter';
import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const useBeyondCTCIBookChapter = (chapterSlug: string) =>
  useQuery({
    queryKey: [`beyondCTCIBookChapter-${chapterSlug}`],
    queryFn: (): Promise<TBeyondCTCIBookChapter> =>
      axios.get(`api/strapi/book/chapters/${chapterSlug}`).then((response) => {
        const chapter = response.data;
        if (!chapter) {
          throw new Error('Unexpected response');
        }
        return chapter;
      }),
    refetchInterval: 60 * 1000,
  });
