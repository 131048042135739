/**
 * Strapi components
 */
export enum StrapiChapterContentDynamicZoneItem {
  bookPanelsDocumentDownload = 'book-panels.document-download',
  bookPanelsBookInfo = 'book-panels.book-info',
  bookPanelsImage = 'book-panels.image',
  bookPanelsProblem = 'book-panels.problem',
  bookPanelsProblemSet = 'book-panels.problem-set',
  bookPanelsInterviewReplayLauncher = 'book-panels.interview-replay-launcher',
  bookPanelsRichText = 'book-panels.rich-text',
  bookPanelsCodeBlock = 'book-panels.code-block',
  bookPanelsExternalResource = 'book-panels.external-resource',
}

/**
 * Get full Strapi URL from path
 *
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path = '') {
  // NOTE: Consider extracting to iio-universal-utils
  return `${window.CONFIG.STRAPI_API_URL || 'http://localhost:1337'}${path}`;
}

/**
 * Strapi's "local provider" for media returns relative paths while
 * the S3 provider returns standard URLs. This function resolves
 * this and produces a standard URL.
 *
 * @param urlOrPath A standard URL or the path component of a URL.
 * @returns
 */
export function resolveMediaUrl(urlOrPath: string): string {
  // NOTE: Consider extracting to iio-universal-utils
  let mediaUrl: string;
  try {
    mediaUrl = new URL(urlOrPath).href;
  } catch {
    mediaUrl = new URL(urlOrPath, getStrapiURL()).href;
  }
  return mediaUrl;
}
