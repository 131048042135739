import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface ITableOfContentsPartTriggerProps {
  bookPart: TBeyondCTCIBookPart;
  open: boolean;
}

const TableOfContentsPartTrigger = ({ open, bookPart }: ITableOfContentsPartTriggerProps) => (
  <>
    <H2 color={open ? `text-black` : `text-bctci-gray-text-default`} size="text-2xl" fontFamily={FONT_FAMILY.HEADING}>
      <span className="font-medium">{bookPart.attributes.Name}</span>
    </H2>
    <ChevronDownIcon className={`ml-auto mt-2 h-6 w-6 min-w-fit ${open ? 'rotate-180' : ''}`} />
  </>
);

export default TableOfContentsPartTrigger;
