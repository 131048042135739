import _ from 'lodash';
import { useState, useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Disclosure } from '@headlessui/react';
import { CheckIcon, LinkIcon, MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

import chatImage from '../../assets/images/SalaryNegotiationChat.svg';
import { InfoModal } from '../../components/Modals/InfoModal';
import { useUser } from '../../userContext';
import { useProductCatalog } from '../../hooks/useProductCatalog';
import { TProduct } from '../../types/ProductCatalog';

export type SalaryNegotiationPageProps = {
  openCart: (salaryNegotiationSku: TProduct, showApplicationConfirmationModal: () => void) => null;
};

const product: {
  name: string;
  description: string;
  details: { id: string; name: string; info: { id: string; label?: string; value: string }[] }[];
} = {
  name: 'Salary Negotiation Package',
  description: `
    <p>
      We have a 94% success rate and have gotten our users an average of $50K more in cash and $70K more in total comp.
      <br /><br />
      <strong>If you get at least $10K more, you'll pay us the greater of $3,000 or 10% of the difference between your initial offer and final offer (at the company where you get hired). Otherwise you pay nothing.</strong> See the FAQ below for details and examples.
      <br /><br />
      When should you book? The moment you think you might be getting an offer. That way we can prevent you from losing leverage.
      Or, if you want to be extra safe about your strategy, you can book as soon as you're motivated to start interviewing.
    </p>
  `,
  details: [
    {
      id: 'pricing',
      name: 'More details about our pricing',
      info: [
        {
          id: 'summary',
          value:
            "If you don't get at least $10K more in first year's TC, you pay nothing. If you get at least $10K more in first year's TC, you pay the greater of: $3,000 or 10% of the difference between the initial offer and the final offer at the company where you get hired. We only count equity toward TC for public companies.",
        },
      ],
    },
    {
      id: 'testimonials',
      name: 'Testimonials',
      info: [
        {
          id: 'anon-testimonial-1',
          label: 'Anonymous Testimonial #1',
          value:
            "My initial offer was 350k TC. My final offer was 500k TC. The first time I attended one of Kevin's webinars, \
          I knew immediately he was a pro, had the experience, the right vibe, and I wanted to work directly with him. \
          And he blew my expectations out of the water. I tell all my friends I hired him as my coach, \
          and it was one of the best decisions I made in my career. I only wish I had done it sooner. \
          - Senior Engineer at Slack (+$150K)",
        },
        {
          id: 'anon-testimonial-2',
          label: 'Anonymous Testimonial #2',
          value:
            'Kevin was a great partner throughout. We ended up milking Amazon for an additional 80k. \
          He no doubt pushed well beyond where I would have landed solo 🤜🤛 \
          - Senior Engineer at Amazon (+$80K)',
        },
        {
          id: 'anon-testimonial-3',
          label: 'Anonymous Testimonial #3',
          value:
            "I just signed! Glad it's over and that we did it well 🤗 \
          Wow - we got more than 100k TC by negotiating. We crushed it. \
          - Senior Engineer at Apple (+$100K)",
        },
      ],
    },
    {
      id: 'faq',
      name: 'FAQ',
      info: [
        {
          id: 'kinds-of-clients',
          label: 'What kind of clients do you work with?',
          value:
            'Our clientele is mainly senior and above engineers in both software and ML, and engineering leadership. All other types of potential clients are accepted on a case-by-case basis.',
        },
        {
          id: 'what-do-i-get',
          label: 'What do I get?',
          value:
            'Once you start working with us, we’ll help you until you have a signed offer, in whatever way you need (as long as it’s related to negotiation). \
          There is no limit on how many calls we do or how many emails we help you write or anything like that. If we work with you, we’re in your corner. No gotchas or technicalities.',
        },
        {
          id: 'what-situations-am-i-charged',
          label: 'In what situations am I charged?',
          value: 'After you sign an offer, if and only if the offer which you signed was increased by at least $10K.',
        },
        {
          id: 'when-will-i-be-charged',
          label: 'If you helped me get at least $10K more, when will my card be charged?',
          value: "We'll charge you when you sign your offer.",
        },
        {
          id: 'what-if-i-decline-an-offer',
          label:
            'What if you help me negotiate more than $10K but I decide not to take the offer as \
            the total comp is not in the range of my expectations. Do I still have to pay?',
          value: "No, you'd pay nothing. The only offers that count (in terms of paying us) are ones that are signed.",
        },
      ],
    },
    {
      id: 'resources',
      name: 'More resources',
      info: [
        {
          id: 'negotiation-101',
          label: 'Negotiation 101: How to not suck at Salary Negotiation',
          value: 'https://www.youtube.com/watch?v=qilnCGjrpso',
        },
        {
          id: 'negotiation-201',
          label: 'Negotiation 201: Advanced salary negotiation tactics for software engineers',
          value: 'https://www.youtube.com/watch?v=55ns1E9L2hk',
        },
        {
          id: 'negotiation-301',
          label: 'Negotiation 301: A Case Study for Software Engineers',
          value: 'https://www.youtube.com/watch?v=GxP99cU9Zmc',
        },
        {
          id: 'negotiation-401',
          label: 'Negotiation 401: Live negotiation with an expert',
          value: 'https://www.youtube.com/watch?v=U1dvBjw2bA8',
        },
        {
          id: 'how-to-negotiate-when-you-are-screwed',
          label: "How to negotiate when you're screwed",
          value: 'https://www.youtube.com/watch?v=YjNF88vi0bk',
        },
        {
          id: 'what-to-sway-when-asked-for-salary-range',
          label:
            'Exactly what to say when recruiters ask you to name the first number… and other negotiation word-for-words',
          value: 'https://interviewing.io/blog/negotiate-salary-recruiter',
        },
      ],
    },
  ],
};

const showApplicationConfirmationModal = () => {
  NiceModal.show(InfoModal, {
    title: 'Thanks for your application!',
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
    ),
    message: (
      <>
        <p className="py-3">Thank you so much for signing up for our salary negotiation package!</p>
        <a
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white no-underline shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
          href="https://calendly.com/alinelerner/salary-negotiation-intro-chat"
          target="_blank"
          rel="noreferrer noopener"
        >
          Click here to book your first call
        </a>
        <p className="py-3">
          If you aren't ready just yet, we'll also send you an e-mail with a link to book. In the meantime, if you have
          any questions at all, please email <a href="mailto:support@interviewing.io">support@interviewing.io</a>.
        </p>
      </>
    ),
  });
};

const SalaryNegotiationPage = (props: SalaryNegotiationPageProps) => {
  const { openCart } = props;

  const { user, updateStateVars } = useUser();

  useEffect(() => {
    if (!_.get(user, 'stateVars.firstSalaryNegotiation')) {
      const stateVarsUpdate = {
        key: 'firstSalaryNegotiation',
        val: new Date(),
      };
      updateStateVars(stateVarsUpdate);
    }
  }, []);

  const { data: productCatalog } = useProductCatalog();
  const salaryNegotiationSkuId = 'salary_negotiation_trial_2';

  const [salaryNegotiationSku, setSalaryNegotiationSku] = useState<TProduct>(null);

  useEffect(() => {
    setSalaryNegotiationSku(productCatalog?.products.find((item) => item.skuId === salaryNegotiationSkuId));
  }, [productCatalog]);

  return !productCatalog ? null : (
    <div className="bg-white">
      <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
        <Tab.Group as="div" className="flex flex-col-reverse">
          <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
            <Tab.Panel>
              <img src={chatImage} className="h-full w-full object-cover object-center sm:rounded-lg" />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.name}</h1>
          <div className="mt-6">
            <h3 className="sr-only">Description</h3>
            <div
              className="space-y-6 text-base text-gray-700"
              dangerouslySetInnerHTML={{ __html: product.description }}
            />
          </div>

          <div className="sm:flex-col1 mt-10 flex">
            <button
              type="submit"
              className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
              onClick={() => openCart(salaryNegotiationSku, showApplicationConfirmationModal)}
            >
              Reserve your spot
            </button>
          </div>

          <section aria-labelledby="details-heading" className="mt-12">
            <div className="divide-y divide-gray-200 border-t">
              {product.details.map((detail) => (
                <Disclosure as="div" key={detail.name}>
                  {({ open }) => (
                    <>
                      <h3>
                        <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                          <span
                            className={classNames(open ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-medium')}
                          >
                            {detail.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                        <ul role="list">
                          {detail.id !== 'resources' &&
                            detail.info.map((info) => (
                              <div key={info.id} className="grid-rows-2">
                                {info.label && <h1 className="text-md ml-3 min-w-0 flex-1 font-bold">{info.label}</h1>}
                                <h1 className="text-md my-3 ml-3 min-w-0 flex-1">{info.value}</h1>
                              </div>
                            ))}
                          {detail.id === 'resources' &&
                            detail.info.map((info) => (
                              <div key={info.id}>
                                <a href={info.value} target="_blank">
                                  <h1 className="text-md ml-3 grid min-w-0 flex-1 grid-cols-12 justify-start font-bold">
                                    <div className="col-span-1">
                                      <LinkIcon className="h-5 w-5" />
                                    </div>
                                    <div className="col-span-11">{info.label}</div>
                                  </h1>
                                </a>
                              </div>
                            ))}
                        </ul>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SalaryNegotiationPage;
