/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useLayoutEffect, useState, MutableRefObject } from 'react';
import { isServer } from './utils';

export const useIsoMorphicEffect = isServer ? useEffect : useLayoutEffect;

export function useLatestValue<T>(value: T) {
  const cache = useRef(value);

  useIsoMorphicEffect(() => {
    cache.current = value;
  }, [value]);

  return cache;
}

export const useEvent =
  // TODO: Add React.useEvent ?? once the useEvent hook is available
  function useEvent<F extends (...args: any[]) => any, P extends any[] = Parameters<F>, R = ReturnType<F>>(
    cb: (...args: P) => R
  ) {
    const cache = useLatestValue(cb);
    return React.useCallback((...args: P) => cache.current(...args), [cache]);
  };

let id = 0;
function generateId() {
  return ++id;
}

const useServerHandoffCompleteState = { serverHandoffComplete: false };
export function useServerHandoffComplete() {
  const [serverHandoffComplete, setServerHandoffComplete] = useState(
    useServerHandoffCompleteState.serverHandoffComplete
  );

  useEffect(() => {
    if (serverHandoffComplete === true) return;

    setServerHandoffComplete(true);
  }, [serverHandoffComplete]);

  useEffect(() => {
    if (useServerHandoffCompleteState.serverHandoffComplete === false)
      useServerHandoffCompleteState.serverHandoffComplete = true;
  }, []);

  return serverHandoffComplete;
}

export const useId =
  // Prefer React's `useId` if it's available.
  React.useId ??
  function useId() {
    const ready = useServerHandoffComplete();
    const [id, setId] = React.useState(ready ? generateId : null);

    useIsoMorphicEffect(() => {
      if (id === null) setId(generateId());
    }, [id]);

    return id != null ? '' + id : undefined;
  };

const Optional = Symbol();
export function optionalRef<T>(cb: (ref: T) => void, isOptional = true) {
  return Object.assign(cb, { [Optional]: isOptional });
}

export function useSyncRefs<TType>(
  ...refs: (React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)[]
) {
  const cache = useRef(refs);

  useEffect(() => {
    cache.current = refs;
  }, [refs]);

  const syncRefs = useEvent((value: TType) => {
    for (const ref of cache.current) {
      if (ref == null) continue;
      if (typeof ref === 'function') ref(value);
      else ref.current = value;
    }
  });

  return refs.every(
    (ref) =>
      ref == null ||
      // @ts-expect-error
      ref?.[Optional]
  )
    ? undefined
    : syncRefs;
}

function resolveType<TTag>(props: { type?: string; as?: TTag }) {
  if (props.type) return props.type;

  const tag = props.as ?? 'button';
  if (typeof tag === 'string' && tag.toLowerCase() === 'button') return 'button';

  return undefined;
}

export function useResolveButtonType<TTag>(
  props: { type?: string; as?: TTag },
  ref: MutableRefObject<HTMLElement | null>
) {
  const [type, setType] = useState(() => resolveType(props));

  useIsoMorphicEffect(() => {
    setType(resolveType(props));
  }, [props.type, props.as]);

  useIsoMorphicEffect(() => {
    if (type) return;
    if (!ref.current) return;

    if (ref.current instanceof HTMLButtonElement && !ref.current.hasAttribute('type')) {
      setType('button');
    }
  }, [type, ref]);

  return type;
}
