import axios from '../../../utils/axios';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { DCCourseProducts, OrderItem } from '../../../types/DCAddSessionTypes';
import { useQuery } from '@tanstack/react-query';

type DCAddSessionPaymentProps = {
  menteeId: string;
  dcCourseId: string;
  email: string;
  orderDetails: OrderItem[];
  dcCourseProducts: DCCourseProducts;
};

type PaymentMethodOption = { id: string; last4: string; _isDefault: boolean; _isExpired: boolean };
type SubmitState =
  | { status: 'Loading' }
  | { status: 'Error'; errorMsg: string }
  | { status: 'Success' }
  | { status: 'NoMethods' }
  | { status: 'LoadedMethods'; methods: PaymentMethodOption[]; selectedPaymentMethodId: null | string };

export const DCAddSessionPayment = (props: DCAddSessionPaymentProps) => {
  const publishableKey = window?.CONFIG.stripe.publishableKey;
  const stripePromise = loadStripe(publishableKey);

  const { menteeId, dcCourseId, orderDetails, email, dcCourseProducts } = props;
  const [paymentState, setPaymentState] = useState({ status: 'Loading' } as SubmitState);

  useQuery({
    queryKey: [`payment-methods`],
    queryFn: () =>
      axios
        .get(`api/orders/paymentMethodsForUser`, { params: { userId: menteeId } })
        .then(({ data }) => {
          if (data.paymentMethods) {
            const { paymentMethods } = data;
            if (paymentMethods.length === 0) {
              setPaymentState({ status: 'NoMethods' });
            } else if (paymentMethods.length > 1) {
              setPaymentState({ status: 'LoadedMethods', selectedPaymentMethodId: null, methods: paymentMethods });
            } else {
              setPaymentState({
                status: 'LoadedMethods',
                selectedPaymentMethodId: paymentMethods[0].id,
                methods: paymentMethods,
              });
            }
          }
          return data;
        })
        .catch((error) => {
          debugger;
          setPaymentState({ status: 'Error', errorMsg: error });
        }),
  });

  const generateIntent = (paymentMethodId: string) =>
    axios.post('/api/orders/processExistingPaymentMethod', {
      skuItems: orderDetails.map((orderItem: OrderItem) => ({ ...orderItem, dcCourseId })),
      paymentMethodId: paymentMethodId,
      userId: menteeId,
    });

  const makePayment = (clientSecret: string, paymentMethodId: string) =>
    stripePromise.then((stripe) => {
      stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      });
    });

  const submitForm = async (paymentMethodId: string) => {
    setPaymentState({ status: 'Loading' });
    try {
      const { data } = await generateIntent(paymentMethodId);
      await makePayment(data.clientSecret, paymentMethodId);
      await axios.post('/api/orders/fullfill', {
        skuItems: orderDetails.map((orderItem: OrderItem) => ({ ...orderItem, dcCourseId })),
        paymentIntentId: data.id,
        userId: menteeId,
      });
      setPaymentState({ status: 'Success' });
    } catch (error) {
      setPaymentState({ status: 'Error', errorMsg: error });
    }
  };

  const totalCost =
    orderDetails
      .map(({ orgId, focus, quantity }) => quantity * dcCourseProducts.unitPriceByOrgAndFocus[`${orgId}${focus}`])
      .reduce((a, b) => a + b, 0) / 100;

  if (paymentState.status === 'Loading') {
    return <div> Loading... </div>;
  }
  if (paymentState.status === 'Error') {
    return (
      <>
        <div className="red mt-8"> Error </div>
        <div>
          {paymentState.errorMsg}
          <a href="/admin/dc-courses"> Return to DC Dashboard</a>{' '}
        </div>
      </>
    );
  }
  if (paymentState.status === 'Success') {
    return (
      <div className="flex-column flex gap-8">
        <h2 className="mt-8">Success! Added sessions to DC Course for user {email || menteeId} </h2>
        <a href={'/admin/users?userId=' + menteeId}> Go to to mentee's user page</a>
      </div>
    );
  }

  if (paymentState.status === 'NoMethods') {
    return (
      <div>User has no saved payment methods. You can still "Add Sessions" without payment from the course page. </div>
    );
  }

  return (
    <>
      <select
        value={paymentState.selectedPaymentMethodId}
        onChange={(e) => {
          const { value } = e.target;
          setPaymentState({ status: 'LoadedMethods', selectedPaymentMethodId: value, methods: paymentState.methods });
        }}
      >
        {paymentState.methods.map((method) => (
          <option value={method.id} key={method.id}>
            Card ending {method.last4} {method._isDefault && '(Default)'} {method._isExpired && '(Expired)'}
          </option>
        ))}
      </select>
      {paymentState.selectedPaymentMethodId && (
        <button
          onClick={() => {
            submitForm(paymentState.selectedPaymentMethodId);
          }}
          className="m-auto rounded p-1 text-lg font-bold text-white"
        >
          Add sessions for ${totalCost}
        </button>
      )}
    </>
  );
};
